import { GENERAL_BFF_PREFIX } from 'constants/general';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

export type UseSubscriptionsTotalCostResponse = {
    totalCosts?: ResultItem[];
    isLoading: boolean;
    error?: ApiError;
};

type UseSubscriptionsTotalCostProps = {
    businessIds: string[];
};

type ResultItem = {
    fixedCost: number;
    variableCost: number;
    totalCost: number;
    currency: string;
};

type TotalCostResult = {
    totalCosts: ResultItem[];
};

const useSubscriptionsTotalCost = ({
    businessIds,
}: UseSubscriptionsTotalCostProps): UseSubscriptionsTotalCostResponse => {
    const { post } = useApi<TotalCostResult, UseSubscriptionsTotalCostProps>();
    const url = `${GENERAL_BFF_PREFIX}/v1/billing-transactions/total-cost`;
    const fetcher: () => Promise<TotalCostResult> = () => post(url, { businessIds });
    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery<TotalCostResult, ApiError>({
        queryKey: [`${url}?${businessIds}`],
        queryFn: fetcher,
        enabled: !!businessIds,
    });

    return {
        totalCosts: data?.totalCosts ?? [],
        isLoading,
        error: error ?? undefined,
    };
};

export default useSubscriptionsTotalCost;
