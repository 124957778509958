import { ETrackingOrigin, ETrackingType } from 'types/tracking';
import { Trans, useTranslation } from 'react-i18next';
import { font } from 'styles/variables';
import Lightbulb from 'assets/illustrations/lightbulb.svg?react';
import PopUp from 'components/info/PopUp';
import TooltipContentComponent from 'components/info/TooltipContent';
import styled from 'styled-components';

const InfoText = styled.span`
    font-size: ${font.size.s};
    margin-bottom: 0.75rem;
    strong {
        font-family: ${font.bold};
    }
`;

type InvoiceInfoProps = {
    origin: ETrackingOrigin;
};

function InvoiceInfo({ origin }: InvoiceInfoProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <InfoText>
            <Trans i18nKey="subscription.statisticsAndInvoiceInfo.shortText" />
            <PopUp
                dataTestId="statisticsAndInvoiceInfo"
                readMore
                content={
                    <TooltipContentComponent
                        headline={t('subscription.statisticsAndInvoiceInfo.infoBoxTitle')}
                        text={t('subscription.statisticsAndInvoiceInfo.infoBoxDescription')}
                        icon={<Lightbulb />}
                    />
                }
                gaTracking={{
                    type: ETrackingType.READ_MORE,
                    origin,
                }}
            />
        </InfoText>
    );
}

export default InvoiceInfo;
