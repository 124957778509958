export enum PATH {
    CRITICAL_ERROR_NO_MENU = '/criticalError',
    TEMPORARY_ERROR_NO_MENU = '/temporaryError',
    WILDCARD = '/*',
    ERROR = '/error',

    FACILITY_MANAGEMENT = '/facilityManagement',
    FACILITY_STATISTICS = '/facilityStatistics',

    INVOICES = '/invoices',

    MY_ACCOUNT = '/myAccount',
    NO_PERMISSION = '/accessDenied',

    SIGNUP = '/signup',
    STARTPAGE = '/',
    SUBSCRIPTION_CREATE = '/createSubscription',
    SUBSCRIPTION_IMPORT = '/import/subscriptions',
    SUBSCRIPTION_OVERVIEW = '/subscriptions',
    SUBSCRIPTION_STATISTICS = '/subscriptionStatistics',
}
